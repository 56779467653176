import React from 'react'
import {useIntl} from "gatsby-plugin-intl"

import Layout from '../../components/layout'




const AboutPage = ({data}) => {
  const intl = useIntl()
  return(
     <Layout>

      <br/>
      <br/>
      <div className='row'
      style={{
        minHeight:'100%'
      }}
      >
      <div className='col-8'>
        
        <h3>{intl.formatMessage({ id: "Datenschutz.AufEinemBlick" })}</h3>
        <h4>{intl.formatMessage({ id: "Datenschutz.Allgemein" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeinText1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.Datenerfassung" })}</h4>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.WerVerantwortung" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.WerVerantwortungText1" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.WieErfassen" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.WieErfassenText1" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.WofuerNutzen" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.WofuerNutzenText1" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.WelcheRechte" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.WelcheRechteText1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AnalyseTools" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsText1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsText2" })}</p>
        <br/>
        <h3>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweise" })}</h3>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseDatenschutz" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseDatenschutzT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseDatenschutzT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseDatenschutzT3" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseHinweise" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseHinweiseT1" })}</p>
        <p>{intl.formatMessage({ id: "global.IstFicotex" })}<br  /> {intl.formatMessage({ id: "global.TomDykStraße" })} <br /> {intl.formatMessage({ id: "global.Bremen" })}</p>
        <p>{intl.formatMessage({ id: "global.TelefonHb" })}<br /> {intl.formatMessage({ id: "global.MailInfo" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseHinweiseT2" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseWiderruf" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseWiderrufT1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseBRecht" })}</h4>
      <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseBRechtT1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseRecht" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseRechtT1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseSsl" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseSslT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseSslT2" })}</p>

        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseAuskunft" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseAuskunftT1" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseWiderspruch" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AllgemeineHinweiseWiderspruchT1" })}</p>
        <br/>
        <h3>{intl.formatMessage({ id: "Datenschutz.Datenerfassung" })}</h3>
        <h4>{intl.formatMessage({ id: "Datenschutz.DatenerfassungCookies" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungCookiesT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungCookiesT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungCookiesT3" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungCookiesT4" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLog" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT1" })}</p>
        <ul >
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT2" })}</p></li>
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT3" })}</p></li>
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT4" })}</p></li>
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT5" })}</p></li>
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT6" })}</p></li>
        <li><p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT7" })}</p></li>
        </ul>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungServerLogT8" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.DatenerfassungKontakt" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungKontaktT1" })}       </p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungKontaktT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungKontaktT3" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.DatenerfassungKontaktT4" })}</p>
        <br/>
        <h3>{intl.formatMessage({ id: "Datenschutz.AnalyseTools2" })}</h3>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsGoogle" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsGoogleT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsGoogleT3" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsIP" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseToolsIPT1" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.AnalyseBrowser" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseBrowserT1" })}</p>
        <p><strong>{intl.formatMessage({ id: "Datenschutz.AnalyseDemografisch" })}</strong></p>
        <p>{intl.formatMessage({ id: "Datenschutz.AnalyseDemografischT1" })}</p>
        <br/>
        <h3>{intl.formatMessage({ id: "Datenschutz.Plugins" })}</h3>
        <h4>{intl.formatMessage({ id: "Datenschutz.PluginsYouTube" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsYouTubeT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsYouTubeT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsYouTubeT3" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsYouTubeT4" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsYouTubeT5" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleWeb" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleWebT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleWebT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleWebT3" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleWebT4" })}</p>
        <br/>
        <h4>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleMaps" })}</h4>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleMapsT1" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleMapsT2" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleMapsT3" })}</p>
        <p>{intl.formatMessage({ id: "Datenschutz.PluginsGoogleMapsT4" })}</p>
      </div>
      
      </div>

    </Layout>
  )

}
export default AboutPage;

